<template>
  <div class="product-card-container">
    <template v-if="!product.loading">
      <div class="card product-card hover-shadow position-relative">
        <!-- Ribbon -->
        <div v-if="product.pinned" class="ribbon-wrapper">
          <div class="ribbon ribbon--down">
            <div class="content">
              <svg
                width="24px"
                height="24px"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="star"
                class="svg-inline--fa fa-star fa-w-18"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <!-- ./Ribbon -->

        <div class="media p-3 pr-5">
          <!-- Product Image -->
          <img class="d-none d-md-flex" v-bind:src="getImage" width="100" />
          <!-- ./Product Image -->

          <div class="media-body ml-2">
            <div class="row align-items-start">
              <div class="col-12 col-md">
                <!-- Product Name -->
                <router-link
                  v-bind:to="'products/detail/' + product._id"
                  target="_blank"
                  class="h6 text-dark"
                  >{{ product.name }}</router-link
                >
                <!-- ./Product Name -->

                <a
                  v-if="productUrl"
                  v-bind:href="productUrl"
                  target="_blank"
                  class="ml-1"
                >
                  <i class="fas fa-external-link-alt fa-sm ml-1 text-muted"></i>
                </a>

                <!-- Stars -->
                <ProductStar
                  class="mt-1"
                  v-bind:star-count="productStarCount"
                />
                <!-- Stars -->

                <!-- Product Price -->
                <ProductPrice
                  class="mt-1"
                  v-bind:price="product.price"
                  v-bind:currency="product.currency"
                  v-bind:order="getProductOrder"
                  v-bind:competitor-exists="getCompetitorCount"
                />
                <!-- ./Product Price -->
              </div>
              <div class="col-12 col-md-auto pbadge-w">
                <ProductBadge
                  v-bind:average-price="averagePrice"
                  v-bind:product-price="product.price"
                  v-bind:product-order="getProductOrder"
                  v-bind:competitors="getProductCompetitors"
                />

                <div class="d-flex justify-content-around">
                  <div
                    class="stat-card mt-4"
                    v-bind:class="{ 'stat-card--first': getProductOrder == 1 }"
                  >
                    <div class="stat-card-title">{{ $t("productOrder") }}</div>
                    <h5 class="mb-0">{{ getProductOrder || "-" }}</h5>
                  </div>
                  <div class="stat-card ml-2 mt-4">
                    <div class="stat-card-title text-truncate">
                      {{ $t("competitor") }}
                    </div>
                    <h5 class="mb-0">
                      {{ getCompetitorCount || "-" }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-auto order-2 order-md-0" style="min-width: 180px">
                <div class="d-flex justify-content-end">
                  <!-- Add Competitor -->
                  <button
                    v-on:click="onAddCompetitor"
                    v-bind:class="[
                      'btn btn-sm product-card-button mr-2',
                      competitorLimitExceed
                        ? 'btn-disabled disabled btn-secondary'
                        : 'btn-success',
                    ]"
                    v-tooltip.bottom="
                      competitorLimitExceed
                        ? 'Rakip Üst Limitine Ulaştınız'
                        : 'Rakip Ekle'
                    "
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <!-- ./Add Competitor -->

                  <!-- Product Setting -->
                  <div class="dropdown">
                    <!-- Setting Button -->
                    <button
                      class="
                        btn btn-sm
                        product-card-button product-card-button--dropdown
                      "
                      type="button"
                      v-bind:id="productDropdownId"
                      data-toggle="dropdown"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <!-- ./Setting Button -->
                    <div
                      v-bind:class="[
                        'dropdown-menu dropdown-menu-right',
                        productDropdownId,
                      ]"
                      v-bind:aria-labelledby="productDropdownId"
                    >
                      <a
                        class="dropdown-item"
                        v-on:click.prevent="onFavoriteProduct"
                        href="#"
                        ><i class="fas fa-star"></i> {{ getFavoriteText }}</a
                      >
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item alert-danger"
                        v-on:click.prevent="$emit('remove-product', product.id)"
                        href="#"
                        ><i class="fas fa-trash"></i> {{ $t("delete") }}</a
                      >
                    </div>
                  </div>
                  <!-- ./Product Setting -->
                </div>

                <!-- Price Info -->
                <div class="d-block mt-3">
                  <PriceList
                    v-bind:competitors="getProductCompetitors"
                    v-bind:currency="product.currency"
                    v-on:average-price="averagePrice = $event"
                  />
                </div>
                <!-- ./Price Info -->
              </div>
              <!-- <div class="col-md-auto my-4 my-md-0">
              <div class="d-flex align-items-center">
                <PriceDonut v-bind:competitor-exists="getCompetitorCount" />
              </div>
            </div> -->
            </div>
          </div>
        </div>

        <!-- Dropdown Toggle -->
        <div
          v-bind:class="[
            'product-card-dropdown-toggle rounded-right',
            showCompetitors ? 'product-card-dropdown-toggle--active' : '',
            !getCompetitorCount ? 'product-card-dropdown-toggle--disabled' : '',
          ]"
          v-on:click="onShowCompetitors"
        >
          <i class="fas fa-chevron-down"></i>
        </div>
        <!-- ./Dropdown Toggle -->
      </div>
      <!-- Competitor List -->
      <CompetitorList
        v-show="
          showCompetitors && product.competitors && product.competitors.length
        "
        v-bind:competitors="product.competitors"
        v-bind:product="product"
        v-bind:CompetitorLimitExceed="competitorLimitExceed"
      />
      <!-- ./Competitor List -->
    </template>
    <CardLoader v-else />
  </div>
</template>
<script>
const ProductStar = () => import("./components/ProductStar/Index.vue");
const LastUpdate = () => import("./components/LastUpdate/Index.vue");
const ProductPrice = () => import("./components/ProductPrice/Index.vue");
const PriceList = () => import("./components/PriceList/Index.vue");
const ProductBadge = () => import("./components/ProductBadge/Index.vue");
// const PriceDonut = () => import("./components/PriceDonut/Index.vue");
const CompetitorList = () => import("./components/CompetitorList/Index.vue");
const CardLoader = () => import("./components/CardLoader/Index.vue");
import { mapActions, mapState } from "vuex";
export default {
  name: "ProductCard",
  components: {
    ProductStar,
    LastUpdate,
    ProductPrice,
    PriceList,
    ProductBadge,
    // PriceDonut,
    CompetitorList,
    CardLoader,
  },
  props: {
    products: {
      default: () => [],
    },
    index: {
      default: 0,
    },
  },
  data() {
    return {
      averagePrice: 0,
      showCompetitors: false,
      showCompetitorModal: false,
    };
  },
  methods: {
    ...mapActions("shared", ["searchProduct"]),
    onShowCompetitors() {
      if (this.getCompetitorCount) {
        this.showCompetitors = !this.showCompetitors;
      }
    },
    onAddCompetitor() {
      if (!this.competitorLimitExceed) {
        this.$emit("add-competitor", this.index);
      }
    },
    onFavoriteProduct() {
      const data = {
        id: this.product.id,
        status: !this.product.pinned,
      };
      this.$emit("product-favorite", data);

      // Ürün Ayar Dropdown
      const dropdownElement = document.querySelector(
        "." + this.productDropdownId
      );
      dropdownElement.classList.remove("show");
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    product() {
      return this.products[this.index];
    },
    productUrl() {
      let result = null;

      if (this.config["site.url"]) {
        result = this.config["site.url"];

        if(this.config["site.slug_prefix"] == "1") {
          result += "/urun/";
        }
        
        result += this.product.slug;
      }

      return result;
    },
    getImage() {
      return this.product.image || "img/no-image.png";
    },
    getCompetitorCount() {
      return (this.product.competitors && this.product.competitors.length) || 0;
    },
    competitorLimitExceed() {
      let result = false;

      const stored = JSON.parse(localStorage.getItem("session"));
      if (stored && stored.priceAnalysis) {
        result =
          this.getCompetitorCount >= stored.priceAnalysis.competitorLimit;
      }

      return result;
    },
    getProductOrder() {
      let result = "-";

      const competitors = this.getProductCompetitors.filter(
        (comp) => comp.price > 0
      );
      if (competitors && competitors.length) {
        const productPrice = parseFloat(this.product.price); // API ürün fiyatı
        if (productPrice) {
          // Rakiplerin fiyatını array içine alır
          const prices = competitors.map((item) => {
            return {
              competitor_id: item.id,
              price: item.convertedPrice || item.price,
            };
          });

          // Ürünün fiyatını rakiplerin fiyatının olduğu diziye ekler
          prices.push({ product_id: this.product.id, price: productPrice });

          // Düşükten yükseğe sıralama yapar
          prices.sort((a, b) => a.price - b.price);

          // Ürünün fiyatına göre sırasını index üzerinden bulur
          const order = prices.findIndex((i) => i.hasOwnProperty("product_id"));
          result = order + 1;
        }
      }

      return result;
    },
    getProductCompetitors() {
      return this.product.competitors || [];
    },
    productStarCount() {
      let result = 0;

      if (this.getCompetitorCount) {
        result = 0;
        const productPrice = this.product.price; // Ürün Fiyatı
        const competitors = this.getProductCompetitors?.filter(
          (comp) => comp.price > 0
        ); // Rakip Listesi

        if (competitors && competitors.length > 0) {
          const competitorPrices = competitors
            .filter((c) => c.price && !isNaN(c.price))
            .map((i) => i.price); // Rakip fiyatları

          if (competitorPrices && competitorPrices.length) {
            let cheapCounter = 0; // Ucuz sayacı
            competitorPrices.forEach((p) => {
              // p = rakip fiyatı
              // Ürün fiyatı rakip fiyatından ucuz ise sayacı artır
              if (productPrice < p) {
                cheapCounter++;
              }
            });
            // 5 yıldız üzerinden kaç puan aldığını hesapla
            result = (cheapCounter * 5) / competitors.length || 1; // Rakip mevcut ise en az 1 yıldız olmalı
          }
        }
      }

      return Math.round(result);
    },
    getFavoriteText() {
      let result = "Favoriye Ekle";

      if (this.product.pinned) {
        result = "Favoriden Çıkar";
      }

      return result;
    },
    productDropdownId() {
      return `productSetting-${this.product.id}`;
    },
  },
  mounted() {
    this.searchProduct({
      sku_no: this.product.skuNo || this.product.sku_no,
      type: "product",
      onSuccess: (result) => {
        const item = result.data && result.data[0];
        if (item) {
          this.product._id = item.id;
          this.product.image = item.image;
          this.product.name = item.name;
          this.product.price =
            item.discount_price && item.discount_price > 0
              ? item.discount_price
              : item.price;
          this.product.currency =
            item.currency_code && item.currency_code.code
              ? item.currency_code.code.toUpperCase()
              : "TRY";
          this.product.quantity = item.quantity;
          this.product.slug = item.slug;

          setTimeout(() => {
            this.product.loading = false;
            this.$emit("update:products", this.products);
          }, 500);
        }
      },
    });
  },
};
</script>
<style lang="scss">
.product-card {
  height: 157px;
  &-container {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &-button {
    width: 30px;
    height: 28px;
    line-height: 15px;
    &:not(:first-child) {
      margin-left: 10px;
    }
    i {
      font-size: 13px;
    }
    &--dropdown {
      background-color: rgb(236, 236, 236);
      &:hover {
        background-color: rgb(219, 219, 219);
      }
    }
  }
  &-dropdown-toggle {
    position: absolute;
    height: 100%;
    width: 25px;
    right: 0;
    background-color: #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      transition: transform 0.3s;
    }
    &--active {
      i {
        transform: rotateZ(180deg);
      }
    }
    &--disabled {
      i {
        color: rgb(158, 158, 158);
      }
    }
  }
}
.stat-card {
  width: 50px;
  text-align: center;
  background-color: #e5e5e5;
  font-weight: bold;
  font-size: 100%;
  padding: 8px 10px;
  border-radius: 8px;
  &-title {
    font-size: 11px;
  }
  &--first {
    position: relative;
    color: #fff;
    background-color: #ff7300;
  }
}
.price-list {
  margin: 0;
  padding: 0;
  &__item {
    list-style-type: none;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
.ribbon-wrapper {
  position: relative;
  .ribbon {
    position: absolute;
    left: 10px;
    top: -2px;
    filter: drop-shadow(1px 3px 2px rgba(black, 0.3));
    > .content {
      color: white;
      font-size: 1.25rem;
      text-align: center;
      font-weight: 400;
      background: rgb(255, 115, 0);
      padding: 8px 2px 4px;
      width: 32;
      min-height: 36;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.down > .content {
      clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - 8px),
        50% 100%,
        0 calc(100% - 8px)
      );
    }
  }
}
.pbadge-w {
  min-width: 166px;
}
@media screen and (max-width: 768px) {
  .product-card {
    height: auto;
  }
}
// .competitors-transition {
//   &-enter,
//   &-leave-to {
//     opacity: 0;
//     max-height: 0px;
//   }
//   &-enter-active,
//   &-leave-active {
//     transition: all 0.2s;
//     max-height: 230px;
//   }
// }
</style>